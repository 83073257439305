.landing-text-container {
  display: flex;
  color: inherit;
  position: absolute;
  align-items: center;
  justify-content: center;
  margin: 0;
  top: 20%;
  border: 1px;
  height: 20%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.532);
  color: white;
}
.landing-text {
  color: inherit;
  font-size: 2rem;
}