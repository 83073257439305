.pilot-program-form-container{
  position: absolute;
  top: 20%;
  width: 90%;
  height: 60%;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.635);
  grid-gap: 20px;
  color: white;
}

.space {
  grid-area: space;
}

.form-input {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}


.button-group {
  grid-area: button-group;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

textarea{
  width: 80vw;
  height: 10vh;
  resize: none;
  padding: 1rem;
}