.nav-bar {
  background-color:  rgba(0, 0, 66, 0.578);
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: stretch;
  top: 0;
  padding-left: 1%;
  padding-right: 1%;
  width: 98%;
  color: white;
  z-index: 1;
}

.nav-bar ul {
  list-style: none;
  margin: 0;
  padding: 1;
  display: flex;
  /* gap: .1rem; */
}

.nav-bar li.active {
  background-color: rgba(61, 61, 137, 0.898);;
}

.nav-bar li:hover {
  background-color: rgba(66, 66, 222, 0.898);;;
}

.nav-bar a {
  text-decoration: none;
  color: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.site-title {
  gap: .5rem;
  font-size: 20pt;
}

.image {
  height: 2rem;
  width: auto;
  object-fit: contain;

}